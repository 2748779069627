/*
File created by suranga for animat the app launcher
*/

/*
app launcher card

*/
$pri: #63BA49;
$sec: #E5E6F1;
.MyBody {
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    height: 100%;
  }
  .grid{
    display: grid;
  }
  .flex{
  display: flex;

  }
.MyContainer {
  display: flex;
  position: absolute;
  margin: 1%;
  box-sizing: border-box;
  
  grid-template-rows: 1fr;
  grid-gap: 10px;

  grid-template-columns: auto;

}
#grid 
{ 
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
}
@media (max-width: 360px)
{ 
  .MyContainer{
    display: grid;
      grid-template-columns: 170px 170px;
  }

}
// .item_size_250{
//   height: 250px;
//   width: 300px;
// }
.item_size_auto{
  height: auto;
  width: auto;
}
.items {
  border-radius: 5px;
  display: grid;
  margin: 5px;
 
  padding: 10px;
  padding-top: 14px;
  // grid-template-rows: 2fr 1fr;
  // grid-gap: 10px;
  cursor: pointer;
  border: 5px solid white;
  transition: all 0.6s;
  
  box-shadow:0 5px 10px rgba(0,0,0,.9);
  background-color: $pri;

 
}
@media only screen and (max-width: 490px) {
  .item_size{
    width: 170px !important;
  }
   
}

.item_size{
  height: 200px;
  width: 200px;
}


.icon-wrapper, .project-name {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper i {
  font-size: 60px;
  color: $sec;
  transform: translateY(0px);
  transition: all 0.6s;
}

.icon-wrapper {
  align-self: end;  
}

.project-name {
  align-self: start;
}
.project-name p {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  color: $sec;  
  transform: translateY(0px);
  transition: all 0.5s;
  text-align: center;
  user-select: none;
}

.items:hover {
  border: 3px solid $sec;
  
  & .project-name p {
    transform: translateY(-10px);
  }
  & .icon-wrapper i {
    transform: translateY(5px);
  }
}
  // end
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.rotate-scale-up {
	-webkit-animation: rotate-scale-up 0.65s linear both;
	        animation: rotate-scale-up 0.65s linear both;
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-up {
    0% {
      -webkit-transform: scale(1) rotateZ(0);
              transform: scale(1) rotateZ(0);
    }
    50% {
      -webkit-transform: scale(2) rotateZ(180deg);
              transform: scale(2) rotateZ(180deg);
    }
    100% {
      -webkit-transform: scale(1) rotateZ(360deg);
              transform: scale(1) rotateZ(360deg);
    }
  }
  @keyframes rotate-scale-up {
    0% {
      -webkit-transform: scale(1) rotateZ(0);
              transform: scale(1) rotateZ(0);
    }
    50% {
      -webkit-transform: scale(2) rotateZ(180deg);
              transform: scale(2) rotateZ(180deg);
    }
    100% {
      -webkit-transform: scale(1) rotateZ(360deg);
              transform: scale(1) rotateZ(360deg);
    }
  }
  
/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
  .scale-out-center {
    -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  } 
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}
