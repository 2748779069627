// Here you can add other styles

//=================== General ===================
body {
  font-size: 12px;
}

.form-group {
  margin-bottom: 8px !important;
}

.form-control {
  height: 28px;
  font-size: 12px;
}

a:hover {
  color: #4b6ae7;
  text-decoration: underline;
}

a {
  color: #0b53f1;
  text-decoration: none;
  background-color: transparent;
}

//================= Breadcrumb ==================
.breadcrumb {
  border: none;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 12px;
}

.breadcrumb-home {
  cursor: pointer !important;
  color: #0b53f1 !important;
}

.breadcrumb-home:hover {
  text-decoration: underline !important;
}

//==================== Breadcrumb Buttons ========
//767px
@media (max-width: 767px) {
  .main-btn-div {
    margin-top: -22px;
    display: flex;
    justify-content: flex-end;
  }

  .main-btn-panel {
    height: 76px;
    margin-left: 0px;
    background-color: white;
    margin-bottom: 20px !important;
  }
}

//768px
@media (min-width: 768px) {
  .main-btn-div {
    padding-top: 8px;
    display: flex;
    justify-content: flex-end;
  }

  .main-btn-panel {
    height: 42px;
    margin-left: 0px;
    background-color: white;
    margin-bottom: 20px !important;
  }
}

.btn-import {
  color: white;
  width: 75px !important;
  margin: 2px !important;
  border-color: #bd8e05 !important;
  background-color: #bd8e05 !important;
}

.btn-import:hover {
  color: white;
  border-color: #a17a04 !important;
  background-color: #a17a04 !important;
}

.btn-import:before {
  content: "\F019";
  font-family: FontAwesome;
  margin-right: 3px !important;
}

.btn-add {
  color: white;
  width: 75px !important;
  margin: 2px !important;
  border-color: #0073f7 !important;
  background-color: #0073f7 !important;
}

.btn-add:hover {
  color: white;
  border-color: #0052af !important;
  background-color: #0052af !important;
}

.btn-view {
  color: white;
  width: 75px !important;
  margin: 2px !important;
  border-color: #337ab7 !important;
  background-color: #337ab7 !important;
}

.btn-view:hover {
  color: white;
  border-color: #225d91 !important;
  background-color: #225d91 !important;
}

.btn-clear {
  color: white;
  width: 75px !important;
  margin: 2px !important;
  border-color: #c16c3b !important;
  background-color: #c16c3b !important;
}

.btn-clear:hover {
  color: white;
  border-color: #864926 !important;
  background-color: #864926 !important;
}

.btn-close {
  color: white;
  background-color: #3b97c1 !important;
  border-color: #3b97c1 !important;
}

.btn-close:hover {
  color: white;
  background-color: #044b6b !important;
  border-color: #044b6b !important;
}

.component-render-panel {
  height: 455px;
  overflow-y: scroll;
}

@media (max-height: 1080px) {
  .component-render-panel {
    height: 550px;
    overflow-y: scroll;
  }
}

//==================== Button ====================

.dx-icon-btn-delete {
  cursor: pointer;
  border: transparent;
  color: #bb4646;
  background-color: transparent;
  padding: 2px 5px !important;
}

.dx-icon-btn-delete :hover {
  border: transparent;
  color: #ac3737;
  background-color: transparent;
}

.dx-icon-btn-delete:before {
  content: "\F014";
  font-family: FontAwesome;
  font-size: 16px;
  vertical-align: middle !important;
}

.btn {
  height: 28px !important;
  color: white !important;
  border-radius: 2px !important;
  padding: 4px 10px !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  margin-top: 0 !important;
}

.btn.disabled {
  cursor: not-allowed;
}

//Override Default Buttons
.btn-link {
  color: #0b53f1 !important;
}

.btn-primary,
.btn-success {
  color: white;
  background-color: #4b6ae7 !important;
  border-color: #4b6ae7 !important;
}

.btn-primary:hover,
.btn-success:hover {
  color: white;
  background-color: #0b53f1 !important;
  border-color: #0b53f1 !important;
}

.btn-secondary,
.btn-warning,
.btn-danger,
.btn-info {
  color: white;
  background-color: #5a6268 !important;
  border-color: #5a6268 !important;
}

.btn-secondary:hover,
.btn-warning:hover,
.btn-danger:hover,
.btn-info:hover {
  color: white;
  background-color: #878c8f !important;
  border-color: #878c8f !important;
}

@font-face {
  font-family: "FontAwesome";
  src: url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
}

.btn-save {
  color: white;
  width: 75px !important;
  margin: 2px !important;
  border-color: #60bb46 !important;
  background-color: #60bb46 !important;
}

.btn-save:hover {
  color: white;
  border-color: #42912d !important;
  background-color: #42912d !important;
}

.btn-save:before {
  content: "\F0C7";
  font-family: FontAwesome;
  margin-right: 3px !important;
}

.btn-delete {
  color: white;
  width: 80px !important;
  margin: 2px !important;
  border-color: #e73939 !important;
  background-color: #e73939 !important;
}

.btn-delete:hover {
  color: white;
  border-color: #ca2424 !important;
  background-color: #ca2424 !important;
}

.btn-delete:before {
  content: "\F014";
  font-family: FontAwesome;
  margin-right: 3px !important;
}

.btn-template {
  color: white;
  width: 80px !important;
  margin: 2px !important;
  border-color: #4b927a !important;
  background-color: #4b927a !important;
}

.btn-template:hover {
  color: white;
  border-color: #2a6853 !important;
  background-color: #2a6853 !important;
}

.btn-template:before {
  content: "\F15B";
  font-family: FontAwesome;
  margin-right: 3px !important;
}

//Aside Application Panel
.aside-tile {
  cursor: pointer;
  width: 86px;
  height: 98px;
  z-index: 999;
  padding: 1px;
  color: black;
  text-align: center;
  vertical-align: top;
  display: inline-block;
  font-weight: bold;
}

.aside-app {
  cursor: pointer;
  border: transparent;
  color: #4b6ae7;
  width: 64px;
  height: 64px;
  font-size: 45px;
  vertical-align: top;
  display: inline-block;
  background-size: 64px;
  background-color: transparent;
}

//Grid Action Buttons
.btn-grid-view {
  cursor: pointer;
  border: transparent;
  color: #0b53f1;
  background-color: transparent;
  padding: 2px 5px !important;
}

.btn-grid-view :hover {
  border: transparent;
  color: #4b6ae7;
  background-color: transparent;
}

.btn-grid-view:before {
  content: "\F06E";
  font-family: FontAwesome;
  font-size: 16px;
  vertical-align: middle !important;
}

.btn-grid-edit {
  cursor: pointer;
  border: transparent;
  color: #0b53f1;
  background-color: transparent;
  padding: 2px 5px !important;
}

.btn-grid-edit :hover {
  border: transparent;
  color: #4b6ae7;
  background-color: transparent;
}

.btn-grid-edit:before {
  content: "\F040";
  font-family: FontAwesome;
  font-size: 16px;
  vertical-align: middle !important;
}

.btn-grid-delete {
  cursor: pointer;
  border: transparent;
  color: #bb4646;
  background-color: transparent;
  padding: 2px 5px !important;
}

.btn-grid-delete :hover {
  border: transparent;
  color: #ac3737;
  background-color: transparent;
}

.btn-grid-delete:before {
  content: "\F00D";
  font-family: FontAwesome;
  font-size: 16px;
  vertical-align: middle !important;
}

//===================== Card =====================
.card-header {
  font-size: 12px;
  font-weight: 450;
  line-height: 8px;
  letter-spacing: 0.02em;
  padding: 0.5rem 1.25rem;
  color: white;
  background-color: #344154;
}

//===================== Navigation Menu =====================
@media (min-width: 992px) {

  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 230px;
  }
}

.sidebar-fixed .sidebar {
  position: fixed;
  z-index: 1019;
  width: 230px;
  height: 100vh;
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -230px;
}

.sidebar {
  font-size: 13px;
  font-weight: 400;
  text-shadow: none;
  letter-spacing: 0.02em;
  color: #fff;
  background: #344154;
}

.sidebar .nav {
  width: 230px;
}

.sidebar .sidebar-nav {
  width: 230px;
}

.sidebar .nav-dropdown-items .nav-item>.nav-link {
  padding-left: 30px !important;
}

.sidebar .nav-dropdown-toggle::before {
  right: 0.5rem;
  height: 10px;
}

.sidebar .nav-item {
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}

.sidebar .nav-link {
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}

.sidebar .nav-link .nav-icon {
  margin-right: 16px;
  width: 16px;
}

.sidebar .nav-item .nav-icon {
  color: #fff;
}

.sidebar .nav-link.active .nav-icon {
  color: #fff;
}

.sidebar .nav-link.active {
  color: #fff;
  background: #0b53f1;
}

.sidebar .nav-link:hover {
  color: #fff;
  background: #0b53f1;
}

.sidebar-minimized .sidebar .nav-link:hover {
  background: #0b53f1;
}

.sidebar-minimized .sidebar .nav-item:hover>.nav-link {
  background: #0b53f1;
}

.sidebar-minimized .sidebar .nav>.nav-dropdown:hover {
  background: #0b53f1;
}

.sidebar .nav-dropdown.open {
  //Blank
}

.sidebar .nav-dropdown.open>.nav-dropdown-items {
  background: rgba(0, 0, 0, 0.2);
}

//===================== Modal =====================
.modal-primary .modal-content,
.modal-success .modal-content {
  border-color: #0b53f1;
}

.modal-primary .modal-header,
.modal-success .modal-header {
  color: #fff;
  background-color: #0b53f1;
}

.modal-secondary .modal-content,
.modal-info .modal-content,
.modal-warning .modal-content,
.modal-danger .modal-content {
  border-color: #5a6268;
}

.modal-secondary .modal-header,
.modal-info .modal-header,
.modal-warning .modal-header,
.modal-danger .modal-header {
  color: #fff;
  background-color: #5a6268;
}

//=============== Pagination ===============
.page-link {
  color: #0b53f1;
}

.page-link:hover {
  color: #0b53f1;
}

.page-item.active .page-link {
  background-color: #0b53f1;
  border-color: #0b53f1;
}

//================== Badge ==================
.badge-primary,
.badge-success {
  color: #fff;
  background-color: #0b53f1;
}

a.badge-primary:hover,
a.badge-primary:focus,
a.badge-success:hover,
a.badge-success:focus {
  color: #23282c;
  background-color: #0b53f1;
}

a.badge-primary:focus,
a.badge-primary.focus,
a.badge-success:focus,
a.badge-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

.badge-info,
.badge-secondary,
.badge-warning,
.badge-danger {
  color: #23282c;
  background-color: #c8ced3;
}

a.badge-info:hover,
a.badge-info:focus,
a.badge-secondary:hover,
a.badge-secondary:focus,
a.badge-warning:hover,
a.badge-warning:focus,
a.badge-danger:hover,
a.badge-danger:focus {
  color: #23282c;
  background-color: #acb5bc;
}

a.badge-info:focus,
a.badge-info.focus,
a.badge-secondary:focus,
a.badge-secondary.focus,
a.badge-warning:focus,
a.badge-warning.focus,
a.badge-danger:focus,
a.badge-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

//===================== Drop down =====================
.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #242d3a;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #242d3a;
}

//======= User Info App Header =======
.user-info {
  background-color: #242d3a;
  padding: 8px;
  text-align: center;
  width: 240px;
  margin-bottom: 2px;
}

.user-link-span {
  color: #fff;
}

.user-info-image {
  max-width: 40%;
  height: auto;
  border-radius: 50em;
  margin-bottom: 10px;
}

.icon-collapse {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  font-size: 14px;
  margin-right: 0 !important;
  padding: 5px 10px !important;
}

//===================== DevExpress =====================
.dx-widget {
  color: #333;
  font-weight: normal;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.35715;
}

.dx-datagrid-headers {
  color: #333;
  font-weight: bold;
}

.dx-checkbox-checked .dx-checkbox-icon {
  color: #0b53f1;
}

.dx-checkbox-indeterminate .dx-checkbox-icon {
  color: #0b53f1;
}

.bg-success {
  background-color: #ffffff !important;
  border-color: #b8b5b5 !important;
}

.rct-node-icon {
  display: none !important;
}

.dx-texteditor-container {
  height: 28px;
}

.dx-texteditor-input {
  min-height: 28px;
}

//======= Menu Search =======
.menu-search {
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  border-color: #d0d0d0;
}

.menu-search-icon {
  cursor: pointer;
  font-size: 18px;
  padding-top: 5px;
  padding-right: 5px;
  color: #b3b7bb;
}

.menu-search-text:focus {
  box-shadow: none;
}

.Test-Class {
  background-color: yellow;
}

.dx-radiobutton-override {
  padding-top: 4px;
  padding-left: 15px;
}

.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
  background: #0b53f1;
}

.dx-radiobutton.dx-state-hover .dx-radiobutton-icon:before {
  border-color: #0b53f1 !important;
}

.dx-radiobutton.dx-state-focused:not(.dx-state-active) .dx-radiobutton-icon:before {
  border-color: #0b53f1 !important;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
  background-color: #0b53f1 !important;
}

//======= Menu =======
.sidebar .nav-item {
  box-shadow: none !important;
}

.sidebar .nav-link {
  box-shadow: none !important;
}

.menu-level-1 {
  border: #ffffff12;
  border-style: solid;
  border-width: 0.01em;
  background-color: #344154 !important;
}

.menu-level-2 {
  border: #ffffff33;
  border-style: solid;
  border-width: 0.01em;
  background-color: #192638 !important;
}

.menu-level-3 {
  border: #ffffff0d;
  border-style: solid;
  border-width: 0.01em;
  font-size: 12px !important;
  padding-left: 10px !important;
  background-color: #101721 !important;
}

//==== Collapsible ====
.collapsible-open {
  cursor: pointer;
}

.collapsible-open:before {
  float: right;
  content: "\f078";
  margin-right: -10px;
  font-family: FontAwesome;
  font-size: 16px;
  vertical-align: middle !important;
}

.collapsible-close {
  cursor: pointer;
}

.collapsible-close:before {
  float: right;
  content: "\F053";
  margin-right: -10px;
  font-family: FontAwesome;
  font-size: 16px;
  vertical-align: middle !important;
}

//======= Loader =======
.full-width-div {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  align-items: center;
  display: flex;
  background: rgba(51, 51, 51, 0.7);
  z-index: 2000;
}

.spin-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spin-container {
  height: 100%;
  width: 100%;
  display: table;
}

.spin-inner {
  vertical-align: middle;
  height: 100%;
  display: table-cell;
}

.spin-cube-grid {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.spin-cube {
  width: 33%;
  height: 33%;
  background-color: #fff;
  float: left;
  -webkit-animation: 1.3s ease-in-out infinite spin-cubeGridScaleDelay;
  animation: 1.3s ease-in-out infinite spin-cubeGridScaleDelay;
}

.spin-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.spin-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.spin-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.spin-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.spin-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.spin-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.spin-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.spin-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.spin-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}


@media (max-width: 991.98px) {
  .app-header {
    width: 100vw;
  }

  .app-body {
    width: 100vw;
  }
}
